<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="20" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">WILD:<br>
                  </font><span class="style6">Weather and Illumunation  Database<br>
                  <font size="3" face="Verdana, Arial, Helvetica, sans-serif" class="style1">Developed with Support from DARPA HID and NSF</font>
                  </span></div></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center"><img src="https://cave.cs.columbia.edu/old/software/wild/images/animation.gif" width="320" height="240"></div></td>
          </tr>
          <tr>
            <td valign="baseline">              <div align="left">
                <p align="left"><font color="#000000" face="Verdana, Arial, Helvetica, sans-serif">WILD is an extensive database of high quality images of an outdoor urban scene, acquired every hour over all seasons. It includes images of the scene taken under a variety of weather and illumination conditions. Each image is tagged with useful ground truth information regarding atmospheric conditions, scene distances and </font><font color="#000000" face="Verdana, Arial, Helvetica, sans-serif">ephemeric data. This website showcases and distributes WILD.</font></p>
                </div>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left">
                  <table width="49%" border="0" align="center" cellpadding="0" cellspacing="10">
                    <tbody><tr align="CENTER">
                      <td width="50%"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/about">About the Database</router-link></font></td>
                    </tr><tr align="CENTER">
                      <td width="50%"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/images">Available&nbsp;Image&nbsp;Sets</router-link></font></td>
                    </tr><tr align="CENTER">
                      <td width="50%"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/videos">Time&nbsp;Lapse&nbsp;Videos</router-link></font></td>
                    </tr><tr align="CENTER">
                      <td width="50%"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/searchengine">Search&nbsp;Engine</router-link></font></td>
                    </tr>
                    <tr align="CENTER">
                      <td width="50%" class="style1"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/copyright">Copyright</router-link></font></td>
                    </tr><tr align="CENTER">
                      <td width="50%"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/download">Download&nbsp;Data</router-link></font></td>
                    </tr><tr align="CENTER">
                      <td><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif"><router-link to="/repository/WILD/sponsor">Sponsors&nbsp;and&nbsp;Acknowledgements</router-link></font></td>
                  </tr></tbody></table>
                </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>